import { Box } from "@xstyled/styled-components";

export default function Icon({ aria, children, ...rest }) {
  return (
    <Box
      as="span"
      role="img"
      aria-label={aria}
      display="inline-block"
      {...rest}
    >
      {children}
    </Box>
  );
}
