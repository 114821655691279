import { FlexBox } from "./Containers";

export default function Button({ children, type, onClick, disabled, ...rest }) {
  return (
    <FlexBox
      as="button"
      type={type || "button"}
      onClick={onClick}
      position="relative"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      bg="transparent"
      border="none"
      borderRadius={10}
      fontFamily="Lufga-Regular"
      fontSize="1rem"
      transition="0.3s all ease"
      cursor={!disabled && "pointer"}
      disabled={disabled}
      {...rest}
    >
      {children}
    </FlexBox>
  );
}
