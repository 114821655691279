import styled, { Box } from "@xstyled/styled-components";

export const Container = styled.divBox`
  position: relative;
  padding-bottom: 12rem;
  color: #fff;

  @media (min-width: desktop) {
    margin: 0 auto;
  }

  @media (min-width: xl_desktop) {
    max-width: 1300px;
  }

  @media (max-width: xl_desktop) {
    max-width: 1140px;
  }

  @media (max-width: lg_desktop) {
    max-width: 950px;
  }

  @media (max-width: desktop) {
    max-width: 100vw;
    padding: 0 1rem 4rem 1rem;
  }
`;

export const FleetContainer = styled.divBox`
  position: relative;
  margin: 0 auto;
  padding: 0;
  color: #fff;

  @media (min-width: xl_desktop) {
    max-width: 1300px;
  }

  @media (max-width: xl_desktop) {
    max-width: 1140px;
  }

  @media (max-width: lg_desktop) {
    max-width: 950px;
  }

  @media (max-width: sm_desktop) {
    max-width: 100vw;
    padding: 0 16px;
  }
`;

export const FleetContainerWide = styled(FleetContainer)`
  @media (max-width: xl_desktop) {
    max-width: 1200px;
  }

  @media (max-width: lg_desktop) {
    max-width: 1000px;
  }
`;

export const BackgroundWrapper = styled.divBox`
  @media (min-width: sm_desktop) {
    min-height: 100vh;
    background-image: ${({ image }) =>
      image &&
      `linear-gradient(
      2deg,
      rgba(27, 27, 27, 0),
      rgba(0, 0, 0, 0.8)
    ),
    url("/assets/backgrounds/${image}.png")`};
    background-position: top ${({ center }) => (center ? "center" : "0% 0%")};
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const MobileBackgroundWrapper = styled.divBox`
  @media (max-width: sm_desktop) {
    height: ${({ height }) => height};
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ image }) =>
      image && `no-repeat center/cover url("/${image}.png")`};
    opacity: 0.75;
    z-index: -1;
  }
`;

export const FlexBox = ({ children, dir, align, justify, wrap, ...rest }) => {
  return (
    <Box
      display="flex"
      flexDirection={dir}
      alignItems={align || "center"}
      justifyContent={justify || "flex-start"}
      flexWrap={wrap}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const GridBox = ({ children, rows, cols, gapY, gapX, ...rest }) => {
  return (
    <Box
      display="grid"
      gridTemplateRows={rows}
      gridTemplateColumns={cols || "1fr 1fr"}
      rowGap={gapY}
      columnGap={gapX}
      {...rest}
    >
      {children}
    </Box>
  );
};
